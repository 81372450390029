/* storybook-check-ignore */
import type { ComponentProps } from 'react';

export type StarFourWayProps = Omit<ComponentProps<'svg'>, 'height' | 'width'> & {
  /**
   * The size prop will set the width and height of the icon.
   * @default 8
   */

  size?: number | string;
};

const StarFourWay = ({ size = 8, ...restOfProps }: StarFourWayProps) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 10 10"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...restOfProps}
  >
    <path
      d="M9.669 4.58c.441.106.441.734 0 .84a5.754 5.754 0 0 0-4.25 4.25c-.105.44-.733.44-.838 0A5.754 5.754 0 0 0 .33 5.42c-.441-.106-.441-.734 0-.84A5.754 5.754 0 0 0 4.581.33c.105-.44.733-.44.838 0a5.754 5.754 0 0 0 4.25 4.25Z"
      fill="currentColor"
    />
  </svg>
);

export default StarFourWay;
