import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Box, Text } from '@opendoor/bricks-next';
import { Link } from '@opendoor/bricks/core';
import { fontSizesNamed } from '@opendoor/bricks/theme/eero';

import { EntryComponent } from '../../cms/entries/entries';
import renderImage from '../../cms/renderer/assets';
import { IComponentIconText } from '../../declarations/contentful';

const pictureIconSize = fontSizesNamed.s5 as number;

const renderIconText = (entry: IComponentIconText) => {
  const fields = entry.fields;
  return (
    <Box columnGap="$1x" padding="$1x" flexDirection="row">
      {fields.textIcon ? (
        <Text tag="p" typography="$headerSmall">
          {fields.textIcon}
        </Text>
      ) : (
        fields.pictureIcon && (
          <Box height={pictureIconSize} width={pictureIconSize}>
            {renderImage({
              src: fields.pictureIcon.fields?.file?.url,
              width: pictureIconSize,
              height: pictureIconSize,
            })}
          </Box>
        )
      )}
      {fields.labelText &&
        documentToReactComponents(fields.labelText, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_, children) => {
              return (
                <Text tag="p" typography="$bodyLarge" marginVertical="auto" marginLeft="$2x">
                  {children}
                </Text>
              );
            },
            [INLINES.HYPERLINK]: (node, children) => {
              return (
                <Link
                  marginLeft={4}
                  href={node.data.uri}
                  target="_blank"
                  analyticsName="cosmos-articles-icon-text"
                  aria-label=""
                >
                  {children}
                </Link>
              );
            },
          },
        })}
    </Box>
  );
};

const IconText: EntryComponent<IComponentIconText> = {
  render: renderIconText,
};

export default IconText;
