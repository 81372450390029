/* storybook-check-ignore */
import styled from '@emotion/styled';
import { Box } from '@opendoor/bricks-next';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentIframe } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const renderIframe = (entry: IComponentIframe) => {
  const { fields } = entry;

  const aspectRatio = fields.ratio ? Math.round(100 / fields.ratio) + '%' : '100%';

  return (
    <EmbeddedEntryWrapper>
      <Box
        position="relative"
        // align in the center on small screens to reduce excess spacing
        alignItems="center"
        justifyContent="center"
        width="100%"
        // @ts-expect-error, aspect ratio not supported
        paddingBottom={aspectRatio}
      >
        <IFrame
          title="contenful-iframe-component"
          src={fields.url}
          allow="autoplay; fullscreen"
          allowFullScreen
        ></IFrame>
      </Box>
    </EmbeddedEntryWrapper>
  );
};

const IframeComponent: EntryComponent<IComponentIframe> = {
  render: renderIframe,
};

export default IframeComponent;
