/* storybook-check-ignore */
import React, { ReactNode } from 'react';

import { Box, BoxProps, Text, TextProps } from '@opendoor/bricks/core';

import StarFourWay from './icons/StarFourWay';

/**
 * @deprecated Use `components/exclusives/badges/ExclusiveBadge` instead.
 */
const ExclusiveBadge: React.FC<
  { textProps?: TextProps; hideIcon?: boolean; children: ReactNode } & BoxProps
> = ({ children, hideIcon, textProps, ...props }) => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      bg="brand50"
      borderRadius="squared"
      lineHeight="s1"
      px="0.5rem"
      color="neutrals0"
      gridGap="0.25rem"
      {...props}
    >
      {!hideIcon && <StarFourWay />}
      <Text color="neutrals0" fontSize="s00" fontWeight="semibold" {...textProps}>
        {children}
      </Text>
    </Box>
  );
};

export default ExclusiveBadge;
