import { DateTime } from 'luxon';

import { humanizeDuration, rescaleAndRoundDurationToLargestUnit } from '../helpers/duration';
import { Badge } from './Badge';

export default function TimeAgoAddedBadge({ addedAt }: { addedAt: string | null }) {
  const timeAgo = addedAt
    ? humanizeDuration(
        rescaleAndRoundDurationToLargestUnit(DateTime.now().diff(DateTime.fromISO(addedAt))),
      )
    : null;
  return (
    <Badge
      color="neutrals100"
      backgroundColor="neutrals0"
      text={timeAgo ? `Added ${timeAgo} ago` : 'Not yet on market'}
      boxShadow="z1"
    />
  );
}
