import { Box, Text } from '@opendoor/bricks-next';
import { Card, Link } from '@opendoor/bricks/core';
import startCase from 'lodash/startCase';
import take from 'lodash/take';
import toLower from 'lodash/toLower';

import { Awaited, EntryComponent } from '../../cms/entries/entries';
import { EXCLUSIVE_RAILS_HOST } from '../../components/globals';
import { IComponentExclusiveListings } from '../../declarations/contentful';
import { ListingWithComputedProperties } from '../../declarations/exclusives/listing';
import {
  getListingThumbnail,
  getListingWithComputedProperties,
} from '../../helpers/exclusives/listing';
import GalleryCard from '../exclusives/gallery/GalleryCard';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

export type ExclusiveListingsReturn = Awaited<ReturnType<typeof exclusivesGalleryLoader>>;

const NUM_EXCLUSIVE_CARDS_TO_SHOW = 10;

const exclusivesGalleryLoader = async (
  input: IComponentExclusiveListings,
): Promise<Array<ListingWithComputedProperties>> => {
  const market = input.fields.market;
  const response = await fetch(
    `${EXCLUSIVE_RAILS_HOST}/api/v1/exclusive_listings/search?${market ? `?market=${market}` : ''}`,
  );
  const body: ListingWithComputedProperties[] = (await response.json()).map(
    getListingWithComputedProperties,
  );
  return take(body, NUM_EXCLUSIVE_CARDS_TO_SHOW);
};

// TODO: Maybe add a header and footer.
const renderExclusivesGallery = (
  entry: IComponentExclusiveListings,
  resolvedData?: ExclusiveListingsReturn,
) => {
  return (
    <>
      <Text marginTop="$32x" tag="h2" typography="$subheaderMedium">
        {entry.fields.title}
      </Text>
      <EmbeddedEntryWrapper>
        <Box>
          <Box width="100%">
            <Box
              tag="ul"
              role="list"
              position="relative"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              gap="$10x"
              marginLeft={0}
              paddingLeft={0}
              style={{
                listStyle: 'none',
              }}
              marginBottom="$10x"
            >
              {resolvedData?.map((listing) => (
                <Box
                  tag="li"
                  key={listing.id}
                  id={`home-${listing.id}`}
                  width="100%"
                  $largerThanSM={{
                    width: '45%',
                  }}
                  $largerThanMD={{
                    width: '30%',
                  }}
                >
                  <Link
                    aria-label={null}
                    href={`/exclusives/homes/${encodeURIComponent(listing.slug)}`}
                    analyticsName={`cosmos-exclusives-home-${listing.id}`}
                  >
                    <GalleryCard
                      listing={listing}
                      exclusivesBadgeColorOverride="#082DE6"
                      url={getListingThumbnail(listing, '_768x512')}
                    />
                  </Link>
                </Box>
              ))}
            </Box>
            <Box $largerThanSM={{ width: '45%' }} $largerThanMD={{ width: '30%' }}>
              <Card
                id="see-more-exclusives-card"
                aria-label={`See more Exclusive listings in ${entry.fields.market}`}
                analyticsName={`cosmos-articles-exclusive-listings-more-${entry.fields.market}`}
                onClick={() => window.open(`/exclusives/gallery/${entry.fields.market}`)}
                variant="secondary"
                bg="#082DE6"
                gap="8px"
                justifyContent="center"
                textAlign="center"
                cursor="pointer"
                gridColumn="auto / span 1"
                _hover={{ bg: '#6985FF' }}
                style={{ paddingLeft: '8px', paddingRight: '8px' }}
              >
                <Text
                  tag="p"
                  color="$contentInversePrimary"
                  typography="$labelMedium"
                  alignItems="center"
                  alignSelf="center"
                  display="flex"
                  columnGap="$5x"
                  flexDirection="row"
                >
                  More Opendoor Exclusives in {startCase(toLower(entry.fields.market))}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M14.1664 6.59103C14.2249 6.71532 14.2541 6.85157 14.2541 6.99976C14.2541 7.14317 14.2249 7.27941 14.1664 7.40848C14.1128 7.53277 14.0252 7.65228 13.9034 7.76701L8.28378 13.1665C8.09378 13.3481 7.86238 13.439 7.58956 13.439C7.41417 13.439 7.25097 13.3959 7.09995 13.3099C6.94892 13.2239 6.82713 13.1091 6.73456 12.9657C6.64687 12.8223 6.60303 12.6598 6.60303 12.4781C6.60303 12.2152 6.70533 11.9809 6.90995 11.7754L10.7761 8.08691H1C0.447715 8.08691 0 7.6392 0 7.08691C0 6.53463 0.447715 6.08691 1 6.08691H10.9589L6.90995 2.22413C6.70533 2.02335 6.60303 1.78911 6.60303 1.52141C6.60303 1.34453 6.64687 1.18439 6.73456 1.04098C6.82713 0.892785 6.94892 0.775665 7.09995 0.689618C7.25097 0.603571 7.41418 0.560547 7.58956 0.560547C7.86238 0.560547 8.09379 0.651375 8.28378 0.83303L13.9034 6.2325C14.0203 6.34723 14.108 6.46674 14.1664 6.59103Z"
                    />
                  </svg>
                </Text>
              </Card>
            </Box>
          </Box>
        </Box>
      </EmbeddedEntryWrapper>
    </>
  );
};

const ExclusivesGallery: EntryComponent<IComponentExclusiveListings, ExclusiveListingsReturn> = {
  render: renderExclusivesGallery,
  loader: exclusivesGalleryLoader,
};

export default ExclusivesGallery;
