import { Text, TextProps } from '@opendoor/bricks/core';

/* storybook-check-ignore */
interface ExclusiveRemainingDaysTextProps {
  daysRemaining?: number;
}

const ExclusiveRemainingDaysText = ({
  daysRemaining,
  ...props
}: ExclusiveRemainingDaysTextProps & TextProps) => {
  if (daysRemaining === 0) {
    return <>Last day on Exclusives</>;
  }

  if (!daysRemaining) {
    return <>Exclusive</>;
  }

  return (
    <>
      Exclusive for{' '}
      <Text as="span" color="neutrals0" fontWeight="bold" {...props}>
        {daysRemaining} more day{daysRemaining !== 1 && 's'}
      </Text>
    </>
  );
};

export default ExclusiveRemainingDaysText;
