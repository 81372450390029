/* storybook-check-ignore */
import { Text } from '@opendoor/bricks/core';
import { DateTime } from 'luxon';

import { OpenHouse } from 'declarations/exclusives/exclusiveListingInfo';

const CT_TIMEZONE = 'America/Chicago';

export default function OpenHouseTagText({
  openHouse,
  title = 'Open',
  timezone = CT_TIMEZONE,
}: {
  openHouse: OpenHouse | undefined;
  title?: string;
  timezone: string | undefined;
}) {
  const startTime = openHouse && DateTime.fromISO(openHouse.start_time);
  const endTime = openHouse && DateTime.fromISO(openHouse.end_time);
  if (!startTime || !endTime) return null;
  const today = DateTime.now().endOf('day');
  const thisWeekEnd = today.plus({ days: 6 });
  if (startTime < thisWeekEnd) {
    return (
      <Text as="span" color="neutrals0" fontSize="s00" fontWeight="semibold">
        {title} - {startTime.setZone(timezone).toFormat('EEE h:mm').replace(':00', '')}-
        {endTime.setZone(timezone).toFormat('h:mm a').replace(':00', '')}
      </Text>
    );
  }
  return (
    <Text as="span" color="neutrals0" fontSize="s00" fontWeight="semibold">
      {title} - {startTime.setZone(timezone).toFormat('EEE MM/d, h:mm').replace(':00', '')}-
      {endTime.setZone(timezone).toFormat('h:mm a').replace(':00', '')}
    </Text>
  );
}
