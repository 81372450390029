import { Flex } from '@opendoor/bricks/core';

import articleEntryLookup from '../../cms/entries/article-v2';
import { EntryComponent } from '../../cms/entries/entries';
import RichTextRenderer from '../../cms/renderer';
import renderImage from '../../cms/renderer/assets';
import { IComponentHighlightBox } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const renderHighlightBox = (entry: IComponentHighlightBox) => {
  const { fields } = entry;

  const style = {
    default: {
      background: 'neutrals40',
    },
    leftBorder: {
      background: 'neutrals40',
      borderLeft: '3px solid #ddd',
    },
    dropShadow: {
      background: 'neutrals0',
      boxShadow: 'z2',
    },
  };

  return (
    <EmbeddedEntryWrapper>
      <Flex
        {...style[fields.variant]}
        borderRadius="semiRounded"
        marginY={9}
        padding={[7, 10, null, null]}
        flexDirection={'row'}
        flexWrap={'wrap'}
        gap={6}
      >
        {fields.image && (
          <Flex
            width={fields.image.fields.file.details.image?.width ?? 200}
            height={'auto'}
            maxWidth={['100%', '50%', null, null]}
            flexDirection={'column'}
            alignSelf="center"
          >
            {renderImage({
              src: fields.image.fields.file?.url,
              width: fields.image.fields.file.details.image?.width ?? 200,
              height: fields.image.fields.file.details.image?.height ?? 200,
            })}
          </Flex>
        )}
        {fields.content && (
          <Flex alignItems={'flex-start'} flexDirection={'column'} flex={1}>
            <RichTextRenderer.Component
              body={fields.content}
              idToLoaderData={{}}
              entryLookup={articleEntryLookup}
            />
          </Flex>
        )}
      </Flex>
    </EmbeddedEntryWrapper>
  );
};

const HighlightBox: EntryComponent<IComponentHighlightBox> = {
  render: renderHighlightBox,
};

export default HighlightBox;
