/* storybook-check-ignore */
import { MouseEvent, useCallback, useReducer } from 'react';
import Modal from 'react-modal';

import styled from '@emotion/styled';
import { Box, Button, ButtonProps, Flex, Icon, IconProps, Text } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChatInactive from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChatInactive';
import Email from '@opendoor/bricks/core/Icon/SpritesheetIcons/Email';
import Neighborhood from '@opendoor/bricks/core/Icon/SpritesheetIcons/Neighborhood';
import Share from '@opendoor/bricks/core/Icon/SpritesheetIcons/Share';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import { motion } from 'framer-motion';
import isString from 'lodash/isString';

import { FACEBOOK_APP_ID } from 'components/globals';

import { Listing } from 'declarations/exclusives/listing';

import { getListingAddressHumanReadable } from '../../helpers/exclusives/listing';
import useDisabledBodyScroll from './hooks/useDisabledBodyScroll';
import useExclusiveAnalytics from './hooks/useExclusiveAnalytics';

const disabledButtonStyle = {
  cursor: 'not-allowed',
  backgroundColor: 'var(--colors-neutrals10)',
  opacity: 0.8,
  border: 0,
};

type ReportEvent = (label: string, additionalContextualData?: Record<string, unknown>) => void;

export type SharingLinksButtonProps = {
  listing: Listing;
  iconSize?: number;
  onSharingSuccess?: (message: string) => void;
  onSharingError?: (message: string) => void;
  source: 'gallery' | 'pdp' | 'saved-homes' | 'add-to-cart';
} & Omit<ButtonProps, '_disabled' | 'aria-label' | 'analyticsName'>;

export default function SharingLinksButton({
  listing,
  onSharingSuccess,
  onSharingError,
  source,
  iconSize = 20,
  ...props
}: SharingLinksButtonProps) {
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const reportEvent = useCallback(
    (label: string, additionalContextualData?: Record<string, unknown>) =>
      trackExclusivesEvent('exclusives-sharing', label, listing, {
        ...additionalContextualData,
        source,
      }),
    [listing],
  );
  const [isInfoOpen, toggleInfoOpen] = useReducer((s) => !s, false);
  return (
    <>
      <Button
        loadingText={''}
        variant="secondary"
        borderRadius="rounded"
        aria-label="Sharing Links Button"
        _disabled={disabledButtonStyle}
        analyticsName="cosmos-exclusives-sharing-button"
        height="36px"
        width="36px"
        minWidth="36px"
        minHeight="36px"
        padding="0"
        boxShadow="0px 5px 10px rgb(29 71 123 / 4%), 0px 1px 5px rgb(29 71 123 / 12%)"
        border="1px solid rgba(207, 215, 251, 0.41)"
        href={getEmailLink(listing)}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          toggleInfoOpen();
          reportEvent('modal');
        }}
        {...props}
      >
        <Flex
          /**
           * This is to ensure the selective spritesheet doesn't expand the button.
           */
          width={0}
        >
          <SelectiveSpritesheet icons={[Share]} />
        </Flex>
        <Icon
          name="share"
          // @ts-expect-error - This is temporary to support existing views that use this button. We should remove this once we've updated the existing views to Novos.
          size={iconSize}
        />
      </Button>
      <ShareLinksModal
        listing={listing}
        isOpen={isInfoOpen}
        onRequestClose={toggleInfoOpen}
        reportEvent={reportEvent}
        onSharingError={onSharingError}
        onSharingSuccess={onSharingSuccess}
      />
    </>
  );
}

function ShareLinksModal({
  listing,
  isOpen,
  reportEvent,
  onRequestClose,
  onSharingError,
  onSharingSuccess,
}: {
  isOpen: boolean;
  listing: Listing;
  onRequestClose?: () => void;
  reportEvent: ReportEvent;
  onSharingSuccess?: (message: string) => void;
  onSharingError?: (message: string) => void;
}) {
  useDisabledBodyScroll(isOpen);
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          display: 'flex',
          backgroundColor: 'rgba(34, 34, 34, 0.33)',
          zIndex: 999,
          width: '100vw',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        },
        content: {
          padding: 0,
          display: 'flex',
          flexShrink: 1,
          flexGrow: 0,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
          inset: 0,
          overflow: 'hidden',
        },
      }}
    >
      <ThemedLite theme="exclusives">
        <SelectiveSpritesheet icons={[Email, Share, ChatInactive, Neighborhood]} />
        <PopupContainer
          onClick={(ev: MouseEvent<HTMLDivElement>) => {
            ev.stopPropagation();
          }}
          animate={{ scale: 1, opacity: 1 }}
          initial={{ scale: 0.5, opacity: 0 }}
          maxWidth="none !important"
        >
          <Flex flexDirection="column" gridGap="1rem">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              gridGap="2rem"
              paddingX="1.625rem"
            >
              <Box height="60px" width="60px">
                <ShareHomeIcon />
              </Box>
              <Button
                variant="icon"
                aria-label="Close"
                analyticsName=""
                boxShadow="none"
                width={33}
                height={33}
                onClick={onRequestClose}
              >
                <Icon name="close" size={16} />
              </Button>
            </Flex>
            <Flex
              flexDirection="column"
              paddingX="1.625rem"
              borderBottom="1px solid rgba(0, 0, 0, 0.1)"
              paddingBottom="1rem"
            >
              <Text fontWeight="semibold" fontSize="s2" color="brand50">
                Share home
              </Text>
              <Text fontWeight="normal" fontSize="s1" opacity="0.8">
                {getListingAddressHumanReadable(listing)}
              </Text>
            </Flex>
            <Flex flexDirection="column" paddingX="1.625rem" gridGap="1rem">
              {getActions(listing, reportEvent, {
                onSharingError,
                onSharingSuccess,
              }).map((props) => {
                return <ActionRow key={props.label} {...props} />;
              })}
            </Flex>
          </Flex>
        </PopupContainer>
      </ThemedLite>
    </Modal>
  );
}

function ContentCopyIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3070_193338" fill="white">
        <rect x="0.5" y="3.5" width="12.5" height="16.25" rx="1.25" />
      </mask>
      <rect
        x="0.5"
        y="3.5"
        width="12.5"
        height="16.25"
        rx="1.25"
        stroke="black"
        strokeWidth="3.75"
        mask="url(#path-1-inside-1_3070_193338)"
      />
      <path
        d="M4.25 1H14.25C14.9404 1 15.5 1.55964 15.5 2.25V16"
        stroke="black"
        strokeWidth="1.875"
      />
    </svg>
  );
}

function MoreHorizIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14.75" y="9.5" width="3.5" height="3.5" rx="1.75" stroke="black" strokeWidth="1.5" />
      <rect x="7.75" y="9.5" width="3.5" height="3.5" rx="1.75" stroke="black" strokeWidth="1.5" />
      <rect x="0.75" y="9.5" width="3.5" height="3.5" rx="1.75" stroke="black" strokeWidth="1.5" />
    </svg>
  );
}

function ShareHomeIcon({ size = 60 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.05" width={size} height={size} rx="8" fill="#082DE6" />
      <path
        d="M28.8 14.9L14.8 25.4C14.2964 25.7777 14 26.3705 14 27V44C14 45.1046 14.8954 46 16 46H44C45.1046 46 46 45.1046 46 44V27C46 26.3705 45.7036 25.7777 45.2 25.4L31.2 14.9C30.4889 14.3667 29.5111 14.3667 28.8 14.9Z"
        fill="#082DE6"
        fillOpacity="0.2"
      />
      <path
        d="M15.4 26.2L29.4 15.7C29.7556 15.4333 30.2444 15.4333 30.6 15.7L44.6 26.2C44.8518 26.3889 45 26.6852 45 27V44C45 44.5523 44.5523 45 44 45H16C15.4477 45 15 44.5523 15 44V27C15 26.6852 15.1482 26.3889 15.4 26.2Z"
        stroke="#082DE6"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
    </svg>
  );
}

function getShareableLink(l: Listing) {
  return (
    (typeof window !== 'undefined' ? window.location.origin : 'https://opendoor.com') +
    '/exclusives/homes/' +
    l.slug
  );
}
function getShareableContent(l: Listing) {
  return `Check out this home for sale on Opendoor Exclusives!\n\n${getShareableLink(l)}`;
}

function getEmailLink(l: Listing) {
  return `mailto:?body=${encodeURIComponent(getShareableContent(l))}`;
}

function getTextMessageLink(l: Listing) {
  return `sms:?&body=${encodeURIComponent(getShareableContent(l))}`;
}

function getFacebookLink(l: Listing) {
  return `https://www.facebook.com/dialog/feed?app_id=${FACEBOOK_APP_ID}&display=popup&link=${getShareableLink(
    l,
  )}&redirect_uri=${getShareableLink(l)}`;
}

function getActions(
  l: Listing,
  reportEvent: ReportEvent,
  {
    onSharingError,
    onSharingSuccess,
  }: {
    onSharingSuccess?: (message: string) => void;
    onSharingError?: (message: string) => void;
  },
): ActionRowProps[] {
  return [
    {
      label: 'Copy link',
      onClick: () => {
        reportEvent('copy', { action_type: 'copy' });
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(getShareableLink(l))
            .catch(() => onSharingError?.('A error occured, try again later!'))
            .then(() => onSharingSuccess?.('Copied to clipboard!'));
        } else {
          onSharingError?.('A error occured, try again later!');
        }
      },
      disabled: typeof navigator !== 'undefined' && !navigator.clipboard,
      icon: <ContentCopyIcon size={16} />,
      ['aria-label']: 'Copy link',
      analyticsName: 'cosmos-exclusives-sharing-links-copy-link-button',
      borderTop: 'none',
    },
    {
      label: 'Email',
      onClick: () => reportEvent('email', { action_type: 'email' }),
      icon: <Icon name="email" size={16} />,
      ['aria-label']: 'Email',
      analyticsName: 'cosmos-exclusives-sharing-links-email-button',
      href: getEmailLink(l),
    },
    {
      label: 'Text message',
      onClick: () => reportEvent('text', { action_type: 'text' }),
      icon: <Icon name="chat-inactive" size={16} />,
      ['aria-label']: 'Text message',
      display: ['flex', null, null, null, 'none'],
      analyticsName: 'cosmos-exclusives-sharing-links-text-message-button',
      href: getTextMessageLink(l),
    },
    {
      label: 'Facebook',
      onClick: () => reportEvent('facebook', { action_type: 'facebook' }),
      icon: <Icon name="neighborhood" size={16} />,
      ['aria-label']: 'Facebook',
      analyticsName: 'cosmos-exclusives-sharing-links-facebook-button',
      href: getFacebookLink(l),
    },
    {
      label: 'More options',
      onClick: async () => {
        reportEvent('more_options', { action_type: 'more_options' });
        navigator
          .share({
            url: getShareableLink(l),
            title: l.exclusive_listing_info.display_address_human_readable,
            text: getShareableContent(l),
          })
          .catch(() => onSharingError?.('A error occurred, try again later!'));
      },
      disabled: typeof navigator !== 'undefined' && !navigator.share,
      icon: <MoreHorizIcon size={16} />,
      display: ['flex', null, null, null, 'none'],
      ['aria-label']: 'More options',
      analyticsName: 'cosmos-exclusives-sharing-links-text-more-options',
    },
  ];
}

type ActionRowProps = {
  label: string;
  icon: IconProps['name'] | JSX.Element;
  onClick?: () => void;
} & ButtonProps & { href?: string };

function ActionRow({ label, icon, onClick, ...props }: ActionRowProps) {
  return (
    <Button
      as={props.href ? 'a' : 'button'}
      variant="link"
      color="neutrals100"
      onClick={onClick}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      paddingTop="1rem"
      borderTop="1px solid rgba(0, 0, 0, 0.05)"
      {...(props.href && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
      _disabled={{ opacity: 0.6 }}
      {...props}
    >
      <Text fontSize="s1" lineHeight="s000" style={{ fontWeight: 500 }}>
        {label}
      </Text>
      {isString(icon) ? (
        <Icon
          name={icon}
          // @ts-expect-error @exclusives fix usage of unavailable size on iconsize
          size={20}
        />
      ) : (
        icon
      )}
    </Button>
  );
}

const PopupContainer = styled(motion(Flex))`
  border-radius: 1rem;
  padding: 1.5rem 0;
  background-color: var(--colors-neutrals0);
  height: fit-content;
  position: relative;
  overflow: hidden;
  z-index: 10;
`;
