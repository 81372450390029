import { Duration, DurationLikeObject } from 'luxon';

const DURATION_UNITS: (keyof DurationLikeObject)[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

/**
 * Rescales the duration (aka explodes), and remove all units exept the largest unit.
 *
 * @example
 * rescaleAndRoundDurationToLargestUnit(Duration.fromObject({ milliseconds: 90000 }))
 * // => Duration.fromObject({ minutes: 1 })
 */
export function rescaleAndRoundDurationToLargestUnit(duration?: Duration) {
  if (!duration) return;
  const rescaledDuration = duration.rescale();
  for (const unit of DURATION_UNITS) {
    const unitValue = rescaledDuration.get(unit);
    if (unitValue) {
      return Duration.fromDurationLike({ [unit]: unitValue });
    }
  }
  return rescaledDuration;
}

const ORDERED_UNITS: (keyof DurationLikeObject)[] = [
  'year',
  'quarter',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
  'millisecond',
];

/**
 * Function used to humanize a Duration should be used instead of the
 * `Duration.toHuman` that has issues related to the Intl.NumberFormat
 * usage on some Safari versions.
 * @param {Duration} duration
 * @returns {string|undefined}
 */
export function humanizeDuration(duration?: Duration): string | undefined {
  if (!duration) return;
  return ORDERED_UNITS.map((unit) => {
    const unitValue = duration.get(unit);
    return unitValue ? `${unitValue} ${unit.toString() + (unitValue != 1 ? 's' : '')}` : undefined;
  })
    .filter(Boolean)
    .join(', ');
}
