// storybook-check-ignore
import { createContext, useContext } from 'react';
import { connect } from 'react-redux';

import * as sessionActions from '@opendoor/shared-fe/build/store/session/actions';
import * as sessionSelectors from '@opendoor/shared-fe/build/store/session/selectors';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { IRequestStatus } from '../account/RequestStatus';

export interface AccountProps {
  human: Map<string, any>;
  infoIsLoaded: boolean;
  infoIsLoading: boolean;
  loggedIn: boolean;
  loginRequestStatus: IRequestStatus;
  humanStatusRequestStatus: IRequestStatus;
  registrationRequestStatus: IRequestStatus;
  serverLoginRedirect: string | undefined;
  email: string;
  hasGeneratedPassword: boolean;
  isNewHuman: boolean;
  maybeFetchSessionInfo: () => void;
  maybeLogOut: () => void;
  logIn: (
    email?: string,
    password?: string,
    googleIDToken?: string,
    facebookIDToken?: string,
  ) => void;
  requestHumanStatus: (email: string) => void;
  register: (
    email?: string,
    name?: string,
    password?: string,
    phone?: string,
    googleIDToken?: string,
    facebookIDToken?: string,
    passwordlessToken?: string,
  ) => void;
  requestResetPassword: (email: string) => void;
}

const mapStateToProps = createSelector(
  [
    sessionSelectors.human,
    sessionSelectors.infoIsLoaded,
    sessionSelectors.infoIsLoading,
    sessionSelectors.loggedIn,
    sessionSelectors.loginRequestStatus,
    sessionSelectors.humanStatusRequestStatus,
    sessionSelectors.registrationRequestStatus,
    sessionSelectors.serverLoginRedirect,
    sessionSelectors.email,
    sessionSelectors.hasGeneratedPassword,
    sessionSelectors.isNewHuman,
  ],
  (
    human: Map<string, any>,
    infoIsLoaded: boolean,
    infoIsLoading: boolean,
    loggedIn: boolean,
    loginRequestStatus: IRequestStatus,
    humanStatusRequestStatus: IRequestStatus,
    registrationRequestStatus: IRequestStatus,
    serverLoginRedirect: string | undefined,
    email: string,
    hasGeneratedPassword: boolean,
    isNewHuman: boolean,
  ) => ({
    human,
    infoIsLoaded,
    infoIsLoading,
    loggedIn,
    loginRequestStatus,
    humanStatusRequestStatus,
    registrationRequestStatus,
    serverLoginRedirect,
    email,
    hasGeneratedPassword,
    isNewHuman,
  }),
);

const mapDispatchToProps = (dispatch: any) => {
  return {
    maybeFetchSessionInfo: () => {
      dispatch(sessionActions.maybeFetchSessionInfo());
    },
    maybeLogOut: () => {
      dispatch(sessionActions.maybeLogOut());
    },
    logIn: (
      email?: string,
      password?: string,
      googleIDToken?: string,
      facebookIDToken?: string,
    ) => {
      dispatch(sessionActions.logIn(email, password, googleIDToken, facebookIDToken));
    },
    requestHumanStatus: (email: string) => {
      dispatch(sessionActions.requestHumanStatus(email));
    },
    requestResetPassword: (email: string) => {
      dispatch(sessionActions.requestPasswordReset(email));
    },
    register: (
      email?: string,
      name?: string,
      password?: string,
      phone?: string,
      googleIDToken?: string,
      facebookIDToken?: string,
      passwordlessToken?: string,
    ) => {
      dispatch(
        sessionActions.register(
          email,
          name,
          password,
          phone,
          googleIDToken,
          facebookIDToken,
          passwordlessToken,
        ),
      );
    },
  };
};

/**
 * This is an HOC that provides access to the session state via props
 *
 * @deprecated Use @opendoor/auth-fe::useAuth instead
 */
export const AccountConnector = connect(mapStateToProps, mapDispatchToProps);
export default AccountConnector;

export const AccountContext = createContext<AccountProps>({} as AccountProps);

/**
 * This is a wrapper around the AccountConnector that provides access via context
 *
 * @deprecated Use @opendoor/auth-fe::useAuth instead
 */
export const AccountContextProvider = AccountConnector(
  ({ children, ...accountProps }: AccountProps & { children: React.ReactNode }) => {
    return <AccountContext.Provider value={accountProps}>{children}</AccountContext.Provider>;
  },
);

/**
 * This is a helper hook to access AccountContext
 *
 * @deprecated Use @opendoor/auth-fe::useAuth instead
 */
export function useAccountContext() {
  return useContext(AccountContext);
}
