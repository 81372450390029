import { useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Box, Flex, Image, Text } from '@opendoor/bricks/core';
import { ColorScheme } from '@opendoor/bricks/system/theme';
import { Token } from '@opendoor/bricks/system/token.types';
import useResizeObserver from '@react-hook/resize-observer';

import { AccountProps } from 'components/shared/AccountConnector';

import { ExclusiveListingInfo } from 'declarations/exclusives/exclusiveListingInfo';

import { ListingWithComputedProperties } from '../../../declarations/exclusives/listing';
import {
  getEarliestOpenHouse,
  remainingDaysRoundedToEndTime,
} from '../../../helpers/exclusives/exclusiveListingInfo';
import {
  getBallparkPriceRange,
  getHasOngoingOrUpcomingOpenHouses,
  getIsAwaitingSellerCommitment,
  getIsOOMLS,
  getIsSellerOfferExperiment,
  getListingAddressHumanReadable,
  getListingPriceCents,
  getOriginalListingPriceCents,
} from '../../../helpers/exclusives/listing';
import TimeAgoAddedBadge from '../badges/TimeAgoAddedBadge';
import { DEFAULT_LISTING_IMAGE, ListingStates } from '../constants/constants';
import ExclusiveBadge from '../ExclusiveBadge';
import ExclusiveRemainingDaysText from '../ExclusiveRemainingDaysText';
import FavoriteButton from '../FavoriteButton';
import OpenHouseTagText from '../openHouse/OpenHouseTagText';
import SharingLinksButton from '../SharingLinksButton';
import VisuallyHidden from '../VisuallyHidden';

const boxCss = css`
  transition: box-shadow 0.25s ease-in-out;
  img {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }

  &:hover {
    img {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transition: scale(1.2);
    }
  }
`;

const actionCss = css`
  width: 40px;
  height: 40px;
  box-shadow: none;
  border: none;
`;

const withoutAnimationBoxCss = `
  box-shadow 0.25s ease-in-out;
`;

interface GalleryCardProps {
  url: string;
  listing: ListingWithComputedProperties;
  exclusivesBadgeColorOverride?: string;
  account?: AccountProps;
  isListingOnWatchlist?: boolean;
  onRemovedFromWatchlist?: (listing: ListingWithComputedProperties) => void;
  onAddedToWatchlist?: (listing: ListingWithComputedProperties) => void;
  onNotifiableEventSuccess?: (message?: string) => void;
  onNotifiableEventError?: (message?: string) => void;
  source?: 'gallery' | 'pdp' | 'saved-homes';
  disableImgHoverAnimation?: boolean;
}

const overlayStates: Partial<Record<ListingStates, string>> = {
  [ListingStates.EL_SOLD]: 'Sold',
  [ListingStates.EL_IN_CONTRACT]: 'In Contract',
};

function OriginalPrice({ price }: { price: number }) {
  return (
    <>
      <VisuallyHidden>Original price: </VisuallyHidden>

      <Text
        as="span"
        fontSize="s0"
        lineHeight="s2"
        margin="0"
        textDecorationLine="line-through"
        textOverflow="ellipsis"
        overflow="hidden"
        opacity="0.6"
        style={{ color: '#202C4A' }}
      >
        {`$${(price / 100).toLocaleString()}`}
      </Text>
    </>
  );
}

function AvailableSoonBadge() {
  return (
    <ExclusiveBadge bg="neutrals100" color="neutrals0" hideIcon>
      Available Soon
    </ExclusiveBadge>
  );
}

const GalleryCard = ({
  url,
  listing,
  exclusivesBadgeColorOverride,
  account,
  isListingOnWatchlist,
  onAddedToWatchlist,
  onRemovedFromWatchlist,
  onNotifiableEventError,
  onNotifiableEventSuccess,
  source = 'gallery',
  disableImgHoverAnimation,
}: GalleryCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showShareButton, setShowShareButton] = useState(true);
  const userEmail: string | undefined = account?.email
    ? account.email
    : account?.human?.get('email');
  const { bedrooms, bathrooms, square_footage, exclusive_listing_info } = listing;
  const isSellerOfferExperiment = getIsSellerOfferExperiment(listing);
  const isOOMLS = getIsOOMLS(listing);
  const address = getListingAddressHumanReadable(listing);
  const priceCents = getListingPriceCents(listing);
  const price = !!priceCents && `$${(priceCents / 100).toLocaleString()}`;
  const originalPriceCents = getOriginalListingPriceCents(listing);
  const houseDetails = `${bedrooms} bd · ${bathrooms} ba · ${square_footage.toLocaleString()} sqft`;
  const daysRemaining = remainingDaysRoundedToEndTime(exclusive_listing_info);
  const isAvailableSoon = listing.el_state === ListingStates.EL_SETUP;
  const isAwaitingSellerCommitment = getIsAwaitingSellerCommitment(listing);

  const hasOngoingOrUpcomingOpenHouses = getHasOngoingOrUpcomingOpenHouses(listing);
  const earliestOpenHouse = useMemo(
    () => getEarliestOpenHouse(listing.exclusive_listing_info),
    [listing.exclusive_listing_info],
  );
  const [numberAndStreet, ...restOfAddress] = address.split(',').map((it) => it.trim());
  const altText = `See details about ${address}`;

  const overlayText = overlayStates[listing.el_state];

  useResizeObserver(ref, (entry) => {
    setShowShareButton(entry.contentRect.width > 300);
  });

  return (
    <Box
      ref={ref}
      css={disableImgHoverAnimation ? withoutAnimationBoxCss : boxCss}
      bg="neutrals0"
      cursor="pointer"
      borderRadius="semiRounded"
      boxShadow="0px 5px 10px rgba(29, 71, 123, 0.1)"
      _hover={{ boxShadow: '0px 5px 12px rgba(29, 71, 123, 0.2)' }}
    >
      <Flex flexDirection="column">
        <Box position="relative" borderTopRadius="semiRounded">
          <Box width="100%" overflow="hidden" borderTopRadius="semiRounded">
            <Image
              id={`galleryCardImgListingId${listing.id}`}
              as="img"
              src={url}
              objectFit="cover"
              alt={altText}
              borderTopRadius="roundedSquare"
              width="100%"
              aspectRatio="3/2"
              loading="lazy"
              onError={() => {
                const el = document.getElementById(`galleryCardImgListingId${listing.id}`) as
                  | HTMLImageElement
                  | undefined;
                if (el) {
                  el.onerror = null;
                  el.src = DEFAULT_LISTING_IMAGE;
                }
              }}
            />
          </Box>

          {overlayText ? (
            <Box
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              position="absolute"
              alignItems="center"
              justifyContent="center"
              borderTopRadius="semiRounded"
              backgroundColor={'rgba(34, 34, 34, 0.5)' as Token<ColorScheme>}
            >
              <Text color="neutrals0" size="s4" fontWeight="bold">
                {overlayText}
              </Text>
            </Box>
          ) : (
            <Flex
              position="absolute"
              top="14px"
              left="14px"
              gridGap="0.375rem"
              flexWrap="wrap"
              paddingRight="0.375rem"
            >
              {!isOOMLS && !isAwaitingSellerCommitment && (
                <>
                  {isSellerOfferExperiment && hasOngoingOrUpcomingOpenHouses && (
                    <ExclusiveBadge
                      bg="neutrals100"
                      color="neutrals0"
                      hideIcon
                      marginRight="0.375rem"
                    >
                      <OpenHouseTagText openHouse={earliestOpenHouse} timezone={listing.timezone} />
                    </ExclusiveBadge>
                  )}
                  {isAvailableSoon && <AvailableSoonBadge />}
                  {isSellerOfferExperiment && (
                    <ExclusiveBadge
                      {...(exclusivesBadgeColorOverride
                        ? { bg: exclusivesBadgeColorOverride }
                        : {})}
                    >
                      Exclusive
                    </ExclusiveBadge>
                  )}
                  {!isSellerOfferExperiment && (
                    <ExclusiveBadge>
                      <ExclusiveRemainingDaysText daysRemaining={daysRemaining} />
                    </ExclusiveBadge>
                  )}
                </>
              )}
              {isAwaitingSellerCommitment && (
                <TimeAgoAddedBadge addedAt={listing.exclusive_listing_info.created_at || null} />
              )}
            </Flex>
          )}
        </Box>
        <Flex
          p="1.25rem"
          paddingRight="0.5rem"
          className="galleryCardContent"
          flexDirection="row"
          justifyContent="space-between"
          gridGap="0.25rem"
        >
          <Flex flexDirection="column" overflowX="hidden" gridGap="0.375rem" flex={1}>
            <Flex flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text
                position="relative"
                fontSize="s2"
                lineHeight="s0"
                fontWeight="bold"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                mb="0.125rem"
                // @ts-expect-error - deprecated color
                color="#0A0F1A"
              >
                {!isAwaitingSellerCommitment && (
                  <>
                    {!price && numberAndStreet}
                    {price !== '$0' && (
                      <>
                        <VisuallyHidden>Current price: </VisuallyHidden>
                        <Text
                          as="span"
                          fontSize="s2"
                          lineHeight="s000"
                          fontWeight="semibold"
                          style={{ color: '#202C4A' }}
                          margin="0"
                          mr="0.375rem"
                        >
                          {price}
                        </Text>
                        {!!originalPriceCents && <OriginalPrice price={originalPriceCents} />}
                      </>
                    )}
                  </>
                )}
                {isAwaitingSellerCommitment && <AwaitingSellerCommitmentPrice listing={listing} />}
              </Text>
              <Flex flexDirection="row" gridGap="0.125rem">
                {showShareButton && (
                  <SharingLinksButton
                    css={actionCss}
                    listing={listing}
                    onSharingError={onNotifiableEventError}
                    onSharingSuccess={onNotifiableEventSuccess}
                    source={source}
                  />
                )}
                <FavoriteButton
                  css={actionCss}
                  listing={listing}
                  isListingOnWatchlist={isListingOnWatchlist}
                  accountEmail={userEmail}
                  addAnalyticsLabel="add"
                  analyticsAdditionalContextualData={{ button_type: 'favorite', source }}
                  onRemovedFromWatchlist={() => onRemovedFromWatchlist?.(listing)}
                  onAddedToWatchlist={() => onAddedToWatchlist?.(listing)}
                  source={source}
                />
              </Flex>
            </Flex>
            <Text
              fontSize="s0"
              lineHeight="s000"
              margin="0"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {!price && !isAwaitingSellerCommitment && restOfAddress.join(', ')}
              {(price || isAwaitingSellerCommitment) && address}
            </Text>
            <Text
              fontSize="s0"
              lineHeight="s000"
              margin="0"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {houseDetails}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const NUMBER_FORMATTER = Intl.NumberFormat('en', { notation: 'compact' });

function AwaitingSellerCommitmentPrice({ listing }: { listing: ListingWithComputedProperties }) {
  return <AwaitingSellerCommitmentPriceInner ballparkPriceRange={getBallparkPriceRange(listing)} />;
}

export function AwaitingSellerCommitmentPriceInner({
  ballparkPriceRange,
}: {
  ballparkPriceRange: ExclusiveListingInfo['ballpark_price_range'];
}) {
  return (
    <>
      {!ballparkPriceRange && (
        <Text lineHeight="s0" style={{ fontWeight: 500, fontSize: '19px' }}>
          Not Available
        </Text>
      )}
      {ballparkPriceRange && (
        <Text lineHeight="s0" style={{ fontWeight: 500, fontSize: '19px' }}>
          {`$${NUMBER_FORMATTER.format(
            ballparkPriceRange.low_estimate_cents / 100,
          )}-$${NUMBER_FORMATTER.format(ballparkPriceRange.high_estimate_cents / 100)}`}
        </Text>
      )}
    </>
  );
}

export default GalleryCard;
