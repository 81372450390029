import Multicard from 'components/articles-v2/Multicard';
import RelatedArticles from 'components/shared/Articles';

import AccordionList from '../../components/articles-v2/AccordionList';
import BasicMetric from '../../components/articles-v2/BasicMetric';
import EmailInputComponent from '../../components/articles-v2/EmailInput';
import ExclusivesGallery from '../../components/articles-v2/ExclusivesGallery';
import HighlightBox from '../../components/articles-v2/HighlightBox';
import IconText from '../../components/articles-v2/IconText';
import Iframe from '../../components/articles-v2/Iframe';
import ImageAsset from '../../components/articles-v2/ImageAsset';
import ImageAssetLink from '../../components/articles-v2/ImageAssetLink';
import ImageCarouselComponent from '../../components/articles-v2/ImageCarousel';
import InstagramImage from '../../components/articles-v2/InstagramImage';
import MetricsCard from '../../components/articles-v2/MetricsCard';
import PropertyListingsComponent from '../../components/articles-v2/PropertyListings';
import Quote from '../../components/articles-v2/Quote';
import SimpleCTA from '../../components/articles-v2/SimpleCTA';
import TwitterTweet from '../../components/articles-v2/TwitterTweet';
import IEntryLookup from './entries';

const articleV2EntryLookup: IEntryLookup = {
  block: {
    componentPropertyListings: PropertyListingsComponent,
    componentMetricsCard: MetricsCard,
    componentIconText: IconText,
    componentRelatedArticles: RelatedArticles,
    componentExclusiveListings: ExclusivesGallery,
    metricBasic: BasicMetric,
    ctaEmailCapture: EmailInputComponent,
    componentImageCarousel: ImageCarouselComponent,
    instagramImageAsset: InstagramImage,
    imageAsset: ImageAsset,
    componentAccordionList: AccordionList,
    componentImageAssetLink: ImageAssetLink,
    componentIframe: Iframe,
    componentQuotes: Quote,
    componentSimpleCta: SimpleCTA,
    componentTwitter: TwitterTweet,
    componentHighlightBox: HighlightBox,
    componentMulticard: Multicard,
  },
  inline: {
    ctaEmailCapture: EmailInputComponent,
  },
};

export default articleV2EntryLookup;
