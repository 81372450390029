import { ReactNode } from 'react';

import { Flex, FlexProps, Text } from '@opendoor/bricks/core';

export type BadgeProps = Omit<FlexProps, 'children' | 'prefix'> & {
  prefix?: ReactNode;
  text: string;
  suffix?: ReactNode;
};

export const Badge = ({ prefix, suffix, text, ...restOfProps }: BadgeProps) => {
  return (
    <Flex
      alignItems="center"
      backgroundColor="neutrals100"
      borderRadius="roundedSquare"
      color="neutrals0"
      display="inline-flex"
      flexShrink={0}
      gap={2}
      height={BADGE_HEIGHT}
      px={3}
      {...restOfProps}
    >
      {prefix && (
        <Flex alignItems="center" flexShrink={0}>
          {prefix}
        </Flex>
      )}

      <Text
        as="span"
        color="inherit"
        fontWeight="medium"
        fontSize="s00"
        lineHeight="s00"
        size="100"
      >
        {text}
      </Text>

      {suffix && (
        <Flex alignItems="center" flexShrink={0}>
          {suffix}
        </Flex>
      )}
    </Flex>
  );
};

const BADGE_HEIGHT = 24;
