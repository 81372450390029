import { Link } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentImageAssetLink, IImageAsset } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';
import ImageAsset from './ImageAsset';

const renderImageLink = (entry: IComponentImageAssetLink) => {
  return (
    <EmbeddedEntryWrapper alignment="text">
      <Link
        href={entry?.fields?.uri}
        target="_blank"
        analyticsName="cosmos-articles-image"
        aria-label=""
      >
        {ImageAsset.render(entry.fields.image as IImageAsset)}
      </Link>
    </EmbeddedEntryWrapper>
  );
};

const ImageAssetLink: EntryComponent<IComponentImageAssetLink> = {
  render: renderImageLink,
};

export default ImageAssetLink;
