/* storybook-check-ignore */
import styled from '@emotion/styled';

// Reference: https://github.com/h5bp/main.css/blob/main/src/_helpers.css
const VisuallyHidden = styled.span`
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  word-wrap: normal;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;

export default VisuallyHidden;
