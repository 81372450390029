/* storybook-check-ignore */
import { TooltipProps } from '@opendoor/bricks/complex';
import dynamic from 'next/dynamic';

// The bricks tooltip needs to attach client-side, not server-side, so we load
// it dynamically to make sure that that happen. If you let it "server-render"
// then the tooltip doesn't work
const DynamicTooltip = dynamic<TooltipProps>(
  () => import('@opendoor/bricks/complex/Tooltip').then((mod) => mod.Tooltip),
  { ssr: false },
);

export default DynamicTooltip;
