import styled from '@emotion/styled';
import Script from 'next/script';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentTwitter } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const TweetAnchor = styled.a`
  color: transparent;
`;

//https://platform.twitter.com/widgets.js

const RenderTwitterTweet = (entry: IComponentTwitter) => {
  const { fields } = entry;

  return (
    <>
      <Script src="https://platform.twitter.com/widgets.js" strategy="lazyOnload" />
      <EmbeddedEntryWrapper alignment="text" display="flex" justifyContent="center">
        {/* eslint-disable @opendoor/no-html-elements */}
        <blockquote className="twitter-tweet">
          <TweetAnchor href={'https://twitter.com/x/status/' + fields.tweetId}>
            Tweet Link
          </TweetAnchor>
        </blockquote>
        {/* eslint-enable @opendoor/no-html-elements */}
      </EmbeddedEntryWrapper>
    </>
  );
};

const TwitterTweet: EntryComponent<IComponentTwitter> = {
  render: RenderTwitterTweet,
};

export default TwitterTweet;
