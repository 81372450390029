import { Box } from '@opendoor/bricks-next';

import { EntryComponent } from '../../cms/entries/entries';
import {
  IComponentImageCarousel,
  IImageAsset,
  IInstagramImageAsset,
} from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';
import ImageAsset from './ImageAsset';
import InstagramImage from './InstagramImage';

const renderImageCarousel = (entry: IComponentImageCarousel) => {
  return (
    <EmbeddedEntryWrapper>
      <Box
        overflow="scroll"
        flexDirection="row"
        gap="$10x"
        position="relative"
        // align in the center on small screens to reduce excess spacing
        alignItems="center"
        paddingBottom="$6x"
        paddingRight="$6x"
        width="100%"
        $largerThanMD={{ marginRight: '-10%' }}
        hoverStyle={{ shadow: '$default' }}
      >
        {entry.fields.images?.map((x) => {
          if (x.sys.contentType.sys.id === 'instagramImageAsset') {
            return InstagramImage.render(x as IInstagramImageAsset);
          }
          if (x.sys.contentType.sys.id === 'imageAsset') {
            return ImageAsset.render(x as IImageAsset);
          }
          return null;
        })}
      </Box>
    </EmbeddedEntryWrapper>
  );
};

const ImageCarouselComponent: EntryComponent<IComponentImageCarousel> = {
  render: renderImageCarousel,
};

export default ImageCarouselComponent;
