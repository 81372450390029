import React, { PropsWithChildren } from 'react';

import styled from '@emotion/styled';
import { AccordionList } from '@opendoor/bricks/complex/Accordion';
import { Box, Button, Flex, H4, Icon, Link, Text } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown';
import ChevronRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight';
import Close16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close16';
import { space } from '@opendoor/bricks/theme/eero';

import { COSMOS_URL } from '../globals';

export const DEFAULT_NAV_MENU_BUTTON_ID = 'nav-menu-button';
export const DEFAULT_NAV_MENU_ID = 'nav-menu';

interface IContainerProps {
  active: boolean;
}

const activeStyle = (props: IContainerProps) => {
  if (!props.active) return 'display: none;';
  return `
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  display: inline;
  `;
};

const Container = styled.div<IContainerProps>`
  width: 320px;
  padding: 20px 45px;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  height: 100%;
  overflow-y: scroll;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transform: translateX(-150px);
  transition: all ease-in-out 0.3s;
  ${activeStyle};
`;

const MenuPanelCategoryA = styled.a`
  font-size: 16px;
  color: #515778;
  display: block;
  padding-bottom: 10px;
`;

export const MenuOverlay = styled.div<IContainerProps>`
  height: 100%;
  width: 100%;
  display: none;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all ease-in-out 0.3s;

  ${activeStyle};
`;

interface IMenuProps {
  title: string;
}

const MenuCategory = (props: IMenuProps) => {
  return (
    <Box>
      <Text fontWeight="semibold" fontSize="s2">
        {props.title}
      </Text>
    </Box>
  );
};

const MenuPanelAppDownload: React.FC<PropsWithChildren<{ href: string }>> = (props) => {
  const { href } = props;
  return (
    <Link
      aria-label="Download the Opendoor app"
      analyticsName="cosmos-download-app-link"
      fontWeight="semibold"
      padding="20px 0 20px 50px"
      lineHeight="s5"
      background="transparent url('https://images.opendoor.com/source/s3/imgdrop-production/c06e544a05124f56802db784568b6e64.svg?preset=square-2048') left
    center no-repeat"
      href={href}
    >
      {props.children}
    </Link>
  );
};

const MenuPanelCategoryLink: React.FC<
  React.PropsWithChildren<{
    href: string;
    elementName?: string;
    active?: boolean;
  }>
> = (props) => {
  return <MenuPanelCategoryA href={props.href}>{props.children}</MenuPanelCategoryA>;
};

const StandaloneLink = styled.a`
  display: block;
  padding: ${space[3]}px;
  font-size: 16px;
  font-weight: 500;
  color: #1c2b4d;
  display: block;
  cursor: pointer;
`;

interface IProps {
  active: boolean;
  navMenuButtonId: string;
  navMenuId: string;
  toggleMenuOpen: () => void;
}
/* storybook-check-ignore */
const MenuPanel = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { active } = props;

  React.useEffect(() => {
    if (active && containerRef.current) {
      containerRef.current.focus();
    }
  }, [active]);

  const trapFocus = (e: any) => {
    if (e.key !== 'Tab') return;

    const containerElements = containerRef.current?.querySelectorAll(
      'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    const firstElement = containerElements && containerElements[0];
    const lastElement = containerElements && containerElements[containerElements.length - 1];

    // tabbing forwards on the last element (Careers)
    if (!e.shiftKey && document.activeElement === lastElement) {
      (firstElement as HTMLElement).focus();
      return e.preventDefault();
    }

    // tabbing backwards on the first element (Close button)
    if (e.shiftKey && document.activeElement === firstElement) {
      (lastElement as HTMLElement).focus();
      e.preventDefault();
    }
  };

  return (
    <>
      <SelectiveSpritesheet icons={[ChevronDown, ChevronRight, Close16]} />
      <Container
        id={props.navMenuId}
        active={active}
        aria-labelledby={props.navMenuButtonId}
        onKeyDown={(e) => e.key === 'Escape' && props.toggleMenuOpen()}
        ref={containerRef}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="6"
        >
          <H4>Menu</H4>
          <Button
            aria-label="Close menu"
            analyticsName="cosmos-menu-close-button"
            variant="icon"
            onClick={props.toggleMenuOpen}
            onKeyDown={trapFocus}
            size="sm"
          >
            <Icon name="close" size={16} />
          </Button>
        </Flex>
        <AccordionList
          variant="compact"
          analyticsName="menu-accordion-top"
          listOfAccordions={[
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/" elementName="homepage">
                    Request an offer
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/reviews">Reviews</MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/stories">Stories</MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'request-an-offer',
              startExpanded: true,
              title: <MenuCategory title="Sell your home" />,
            },
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/w/homes">Browse homes</MenuPanelCategoryLink>

                  <MenuPanelCategoryLink
                    href="https://opendoor.com/exclusives"
                    elementName="exclusives"
                  >
                    Exclusives
                  </MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'buy-a-home',
              title: <MenuCategory title="Buy a home" />,
            },
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/w/builder">Builder trade-up</MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'buy-and-sell',
              title: <MenuCategory title="Buy and sell" />,
            },
          ]}
        />
        <MenuPanelAppDownload href="https://opendoor.app.link/homepage">
          Download App
        </MenuPanelAppDownload>
        <AccordionList
          analyticsName="menu-accordion-bottom"
          variant="compact"
          listOfAccordions={[
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/w/agents#alp-buy--form">
                    Make an offer
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/agents">Request an offer</MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'agents',
              title: <MenuCategory title="Agents" />,
            },
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/w/vendor-partnerships">
                    Vendors
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/partner/builder">Builder</MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'partners',
              title: <MenuCategory title="Partners" />,
            },
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href="/w/about">Company + Mission</MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/faq">FAQ</MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href={`${COSMOS_URL}/articles/topic/press`}>
                    Press
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/contact-us">Contact</MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'about',
              title: <MenuCategory title="About" />,
            },
            {
              variant: 'secondary',
              expandOnFocus: true,
              children: (
                <Box width="80%">
                  <MenuPanelCategoryLink href={`${COSMOS_URL}/articles/topic/blog`}>
                    Blog
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href={`${COSMOS_URL}/articles/topic/education`}>
                    Education
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/home-sale-calculator">
                    Home sale calculator
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href="/w/mortgage-calculator">
                    Mortgage calculator
                  </MenuPanelCategoryLink>
                  <MenuPanelCategoryLink href={`${COSMOS_URL}/articles/topic/briefs`}>
                    Briefs
                  </MenuPanelCategoryLink>
                </Box>
              ),
              panelId: 'resources',
              title: <MenuCategory title="Resources" />,
            },
          ]}
        />
        <StandaloneLink href="/w/faq">FAQ</StandaloneLink>
        <StandaloneLink href="https://help.opendoor.com">Help Center</StandaloneLink>
        <StandaloneLink href="/jobs" onKeyDown={trapFocus}>
          Careers
        </StandaloneLink>
      </Container>
    </>
  );
};

MenuPanel.defaultProps = {
  navMenuButtonId: DEFAULT_NAV_MENU_BUTTON_ID,
  navMenuId: DEFAULT_NAV_MENU_ID,
};

export default MenuPanel;
