import { Box, Button, Text } from '@opendoor/bricks-next';

import { EntryComponent } from 'cms/entries/entries';

import { IComponentSimpleCta } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const renderSimpleCTA = (entry: IComponentSimpleCta) => {
  const { fields } = entry;
  return (
    <EmbeddedEntryWrapper alignment="text">
      <Box
        padding="$10x"
        alignItems={'center'}
        backgroundColor="$backgroundTertiary"
        borderRadius="$16x"
        flexDirection={'column'}
      >
        {fields.headline && (
          <Text tag="h5" typography="$subheaderMedium" textAlign="center" color="$contentPrimary">
            {fields.headline}
          </Text>
        )}
        {fields.description && (
          <Text
            tag="p"
            textAlign="center"
            marginVertical="$5x"
            typography="$bodyMedium"
            $largerThanXS={{ typography: '$bodyLarge' }}
            color="$contentTertiary"
          >
            {fields.description}
          </Text>
        )}
        {fields.ctaLink && fields.ctaText && (
          <Button
            onPress={() => {
              window.open(fields.ctaLink, '_blank');
            }}
            analyticsName="simple-cta-get-offer-button"
            variant="primary"
            size="small"
            label="Get a cash offer"
          />
        )}
      </Box>
    </EmbeddedEntryWrapper>
  );
};

const SimpleCTA: EntryComponent<IComponentSimpleCta> = {
  render: renderSimpleCTA,
};

export default SimpleCTA;
