import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks-next';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentQuotes } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const Blockquote = styled.blockquote`
  margin-bottom: 0;
  margin-left: 32px;
  margin-right: 0;
  position: relative;

  @media (min-width: 769px) {
    margin-left: 0;
  }

  p::before {
    content: '“';
    display: inline-block;
    position: absolute;
    right: calc(100% + 16px);
  }
`;

const renderQuote = (entry: IComponentQuotes) => {
  const { fields } = entry;
  return (
    <EmbeddedEntryWrapper>
      <Blockquote>
        <Text tag="p" typography="$headerMedium">
          {fields.content}
        </Text>
      </Blockquote>
    </EmbeddedEntryWrapper>
  );
};

const Quote: EntryComponent<IComponentQuotes> = {
  render: renderQuote,
};

export default Quote;
