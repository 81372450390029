import { Flex } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentMetricsCard, IMetricBasic } from '../../declarations/contentful';
import BasicMetric from './BasicMetric';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const renderMetricsCard = (entry: IComponentMetricsCard) => {
  return (
    <EmbeddedEntryWrapper alignment="text">
      <Flex
        gridGap={'6'}
        pb="4"
        pt="4"
        width="100%"
        flexWrap={['nowrap', null, 'wrap']}
        flexDirection={['column', null, 'row']}
      >
        {entry.fields.metrics?.map((x) => {
          if (x.sys.contentType.sys.id === 'metricBasic') {
            return BasicMetric.render(x as IMetricBasic);
          }
          return null;
        })}
      </Flex>
    </EmbeddedEntryWrapper>
  );
};

const MetricsCard: EntryComponent<IComponentMetricsCard> = {
  render: renderMetricsCard,
};

export default MetricsCard;
